.site-search {
	width: 45px;
	height: 45px;
	margin-left: 10px;
	margin-top: 8px;
	font-size: 20px;
	border-radius: 50%;
	line-height: 40px;
	text-align: center;
	transition: all 0.3s;
	// border: 1px solid #d7e0e3;
	color: #576880;

	@include themify($themes) {
		color: themed("colorText");		
	}
	&:hover,
	&:focus {
		@include themify($themes) {
			color: themed("colorText");			
		}
		background: #d7e0e3;
	}
}
.search-wrap {
	padding: 60px 30px;
	position: relative;

	&-in {
		max-width: 970px;
		margin: 0 auto;
		position: relative;
	}
	.search-close {
		position: absolute;
		right: 0;
		top: 0;
		padding: 10px;
		font-size: 24px;
		background: none;
		border: none;
		margin: 0;

		@include themify($themes) {
			color: themed("colorFieldsBorderF");
		}
		&::before {
			display: none;
		}
		&:hover,
		&:focus {
			@include themify($themes) {
				color: themed("colorText");
			}
		}
	}
	.form {
		padding-bottom: 50px;
		input {
			height: 60px;
			padding: 10px;
			font-size: 22px;
		}
	}
}
.search-resul-card {
	.card__title + p {
		padding-top: 10px;
		margin-top: 0;
	}
}
