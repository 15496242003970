$themes: (
	light: (
		colorBackground: white,
		colorBackgroundBody: #f7f7f8,
		colorText: #101928,
		colorTextAdditional: #0d232b,
		logoImg: url(../shared/img/logo/logo_light.png),
		colorHover: #fafbfe,
		colorFolderHover: #f0eeee,
		colorBorder: #eff1f5,
		colorBorderHover: #e6e9ed,
		colorIcon: #dddddd,
		imgInvert: invert(0%),
		colorFieldsBorder: #d7e0e3,
		colorFieldsBorderF: #8d8d8d,
		colorBubble: rgba(242, 244, 247, 0.65),
		colorBubbleActive: rgba(234, 238, 255, 0.6),
		colorScrollbar: #b4bfd0,
		colorFitness: #646777,
		sidebarBg: #000,
		sidebarBgHover: #2b2b2b,
		sidebarLinkColor: #f3f3f3
	),
	dark: (
		colorBackground: #232329,
		colorBackgroundBody: #2a2a31,
		colorText: #dddddd,
		colorTextAdditional: #999999,
		logoImg: url(../shared/img/logo/logo_dark.png),
		colorHover: #38373f,
		colorFolderHover: #ffffff,
		colorBorder: #333246,
		colorBorderHover: #23232a,
		colorIcon: #605f7b,
		imgInvert: invert(100%),
		colorFieldsBorder: #33333a,
		colorBubble: rgba(68, 79, 97, 0.65),
		colorBubbleActive: rgba(92, 104, 156, 0.6),
		colorScrollbar: #606071,
		colorFitness: #ffffff,
		sidebarBg: #fff,
		sidebarBgHover: #f7f7f7,
		sidebarLinkColor: #000
	)
);

@mixin themify($themes) {
	@each $theme, $map in $themes {
		.theme-#{$theme} & {
			$theme-map: () !global;
			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}");
				$theme-map: map-merge(
					$theme-map,
					(
						$key: $value
					)
				) !global;
			}
			@content;
			$theme-map: null !global;
		}
	}
}

@function themed($key) {
	@return map-get($theme-map, $key);
}

$color-accent: #009C00;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #67768c;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #fcc14a;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #e85f5b;
$color-red-hover: darken($color-red, 10%);

$color-blue: #000;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
