.no-results {
	padding: 20px;
	text-align: center;

}

.formInner {
	width: 100%;
}

.css-bg1rzq-control {
	height: 40px;
}

.css-1hwfws3 {
	padding: 0 8px;
}

.css-1szy77t-control {
	min-height: 40px;
}

.css-dvua67-singleValue {
	margin-left: 0;
	margin-right: 0;
}

.react-select .css-1g6gooi {
	margin: 0;
	padding: 0;

	input {
		height: 100%;
	}
}

.form__field-group {
	h3 {
		font-size: 16px;
		margin-bottom: 0;
	}

	.card-body {
		@include themify($themes) {
			box-shadow: 0 5px 10px rgba(themed("colorText"), 0.2);
		}
	}

	&-field {
		.checkbox-btn {
			margin-top: 10px;
		}
	}
}

.filter-widget {
	.form__form-group {
		min-width: 150px;

		&-field {
			min-height: 40px;
		}
	}

	.btn-toolbar {
		margin-top: 25px;
	}

	li {
		vertical-align: top;
	}
}

.cs-serach-filter {
	padding-bottom: 25px;
}

.table-actions {
	.btn {
		padding: 2px 10px;
		font-size: 12px;
		margin-right: 8px;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}
	}
}

.tabs {
	overflow: inherit;
}

.tabs .cs-form-tabs {
	margin: -30px -30px 10px;
	padding: 0;
	border-radius: 5px 5px 0 0;
	border: none;

	@include themify($themes) {
		background: themed("colorBorder");
	}

	li .nav-link {
		border: none;
		padding: 15px 30px;
		position: relative;
		border-radius: 0;

		&::after {
			content: "";
			width: 0;
			height: 2px;
			position: absolute;
			left: 50%;
			bottom: 0;
			transition: all 0.3s;

			@include themify($themes) {
				background-color: themed("colorTextAdditional");
			}
		}

		&:hover,
		&:focus {
			@include themify($themes) {
				background-color: themed("colorBorderHover");
			}
		}

		&.active {
			&::after {
				opacity: 1;
				width: 100%;
				left: 0;
			}
		}
	}
}

.card__seperation {
	margin-top: 20px;
}

.form__form-group-field-with-btn {
	position: relative;
	padding-right: 100px;

	.input-btn-r {
		position: absolute;
		right: 0;
		top: 0;
		padding: 8px 12px 9px;
	}
}

.add-horse-modal {
	max-width: 670px;
}

.anlytics-card {
	li {
		@include themify($themes) {
			color: themed("colorFieldsBorderF");
		}

		span {
			float: left;
			width: 64%;
		}

		strong {
			float: left;
			width: 36%;
			text-align: right;

			@include themify($themes) {
				color: themed("colorText");
			}
		}
	}
}

.card_cs_title {
	margin-bottom: 30px;

	h5 {
		text-transform: uppercase;

		.cnt {
			font-size: 12px;
			font-weight: normal;
			padding: 5px 10px;
			border-radius: 4px;
			margin-left: 10px;
			background: $color-blue;
			float: right;
			line-height: 1;
			color: #fff;

			&-price {
				background: $color-accent;
			}
		}
	}
}

.form-section {
	position: relative;

	+.form-section {
		padding-top: 30px;
		margin-top: 30px;

		@include themify($themes) {
			border-top: 1px solid themed("colorFieldsBorder");
		}

		&::before {
			content: "OR";
			width: 30px;
			height: 30px;
			border-radius: 50%;
			line-height: 30px;
			text-align: center;
			position: absolute;
			left: calc(50% - 15px);
			top: -15px;
			background: #fff;
			font-size: 14px;
			font-weight: bold;

			@include themify($themes) {
				border: 1px solid themed("colorFieldsBorder");
				color: themed("colorFieldsBorderF");
			}
		}
	}
}

.modal__actions {
	button {
		min-width: 100px;
		padding: 4px 25px;
	}
}

.card__title h5 {
	font-size: 16px;
}

.rerports-card {
	.card__title {
		margin-bottom: 15px;
	}

	.card-subtitle {
		@include themify($themes) {
			color: themed("colorFieldsBorderF");
		}

		text-transform: none;
		margin-top: 10px;
	}

	ul {
		li {
			position: relative;

			a {
				display: block;
				padding: 10px 0;
			}

			&::after {
				content: "";
				width: calc(100% + 30px);
				height: 1px;
				position: absolute;
				left: 0;
				bottom: 0;

				@include themify($themes) {
					background: themed("colorFieldsBorder");
				}
			}
		}
	}
}

.page-head {
	padding-top: 30px;

	.form__form-group-field {
		background: #fff;
	}

	.cs-dropdown {
		margin-top: 30px;

		button {
			background: #fff;
		}
	}
}

.form__form-group-field-with-select {
	.form__form-group-input-wrap {
		input {
			&:focus {
				position: relative;
				z-index: 2;
			}
		}

		&:last-child {
			width: 40%;
			margin-left: -1px;
		}

		.react-select__control {
			&--is-focused {
				position: relative;
				z-index: 2;
			}
		}
	}
}

.forget-password {
	width: 438px;
}

.doc-list {
	margin-top: 20px;

	.dropzone__img-main {
		position: relative;
		margin: 0 15px 15px 0;

		&:hover {
			.dropzone__img-delete {
				opacity: 1;
			}

			.dropzone__img::before {
				background-color: rgba(25, 25, 25, 0.6);
			}
		}
	}

	.dropzone__img-delete {
		background: none;
		font-size: 9px;
		border: 1px solid white;
		text-transform: uppercase;
		padding: 2px 7px;
		line-height: 10px;
		border-radius: 0;
		color: #fff !important;

		&::before {
			display: none;
		}
	}

	.dropzone__img {
		width: 150px;
		margin: 0;

		@include themify($themes) {
			color: themed("colorText");
		}
	}

	.doc-card {
		display: block;
		position: relative;
		width: 100px;
		height: 100px;
		box-shadow: 0 4px 8px rgba(140, 140, 140, 0.3);
		border-radius: 6px;
		margin-right: 6px;

		a {
			@include themify($themes) {
				color: themed("colorText");
			}
		}

		&:hover,
		&:focus {
			box-shadow: 0 6px 16px rgba(140, 140, 140, 0.3);
		}

		.doc-close {
			position: absolute;
			right: 0;
			top: 0;
			background: #fff;
			border-radius: 50%;
			color: #000;
			padding: 3px;
			font-size: 12px;
			right: -6px;
			top: -6px;
			transition: all 0.3s;
			line-height: 14px;
			width: 20px;
			height: 20px;
			margin: 0;

			&::before {
				display: none;
			}

			&:hover,
			&:focus {
				transform: rotate(180deg);
			}
		}

		&-in {
			display: block;
			overflow: hidden;
			width: 100px;
			height: 100px;
			line-height: 100px;
			text-align: center;
			border-radius: 6px;
			margin-bottom: 10px;
			font-size: 46px;

			@include themify($themes) {
				color: themed("colorText");
				background: themed("colorBorder");
			}
		}
	}
}

.cs-dropzone-doc,
.doc-list {
	.dropzone__img {
		&::after {
			content: "\e81d";
			font-family: Linearicons-Free;
			speak: none;
			font-style: normal;
			font-weight: 400;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			font-size: 44px;
			position: absolute;
			left: 0;
			width: 100%;
			top: 30px;
			text-align: center;
		}
	}
}

.react-grid-Cell__value .btn-sm {
	padding: 2px 10px;
}

.react-select__menu-list .react-select__group-heading {
	font-size: 14px;
	padding: 10px;

	@include themify($themes) {
		color: themed("colorText");
		background: themed("colorBackgroundBody");
	}
}

@media screen and (min-width: 768px) {
	.page-head .cs-dropdown {
		text-align: right;
		margin-top: 0;
	}
}

@media screen and (max-width: 500px) {
	.forget-password {
		width: 100%;
	}
}

.theme-light .card-body {
	box-shadow: 0 1px 2px rgba(16, 25, 40, .15);
	border-radius: 4px;
}

.theme-light .table.table-striped tbody tr:nth-of-type(odd) {
	background: none;
}

.theme-light .table.table-striped tbody tr:nth-of-type(even) {
	background: #f5f4f4;
}

.slide-in {
	&-wrap {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
		z-index: 98;

		.slide-in-on & {
			opacity: 1;
			visibility: visible;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(#0A0A0A, 0.5);
			transition: all 0.3s ease;
			opacity: 0;

			.slide-in-on & {
				opacity: 1;
			}
		}
	}

	&-main {
		position: absolute;
		right: 0;
		top: 60px;
		max-width: 500px;
		width: 100%;
		height: calc(100% - 60px);
		transition: all 0.5s ease;
		transform: translateX(100%) translateX(20px);
		background: #fff;
		padding: 35px;
		overflow: auto;
		box-shadow: 0 4px 5px rgba(#000, 0.5);

		.slide-in-on & {
			transform: none;
		}
	}

	&-head {
		padding-right: 50px;
		position: relative;
		padding-bottom: 20px;

		h2 {
			font-size: 21px;
			float: left;

			a {
				font-size: 12px;
				display: inline-block;
				vertical-align: middle;
				margin-left: 6px;
			}
		}

		p {
			float: right;
			font-size: 21px;
			font-weight: 600;
			line-height: 36px;
			margin: 0;
		}

		&::after {
			content: "";
			display: table;
			clear: both;
		}

		button.slide-in-close {
			position: absolute;
			right: -10px;
			top: -9px;
			padding: 10px;
			font-size: 24px;
			background: none;
			border: none;
			margin: 0;

			&:hover,
			&:focus {
				background: none;
			}

			&::before {
				display: none;
			}
		}
	}

	&-content {
		&-item {
			margin-bottom: 15px;
			border-bottom: 1px solid #DCDCDC;
			padding-bottom: 15px;

			&-head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;

				.btn {
					margin: 0;
				}
			}

			h3 {
				font-size: 16px;
				font-weight: bold;
				margin: 0;
				line-height: 1.25;
			}

			&-list {
				list-style: none;
				margin: 0 -10px;
				padding: 0;

				&::after {
					content: "";
					display: table;
					clear: both;
				}

				>li {
					float: left;
					width: 100%;
					padding: 0 10px 5px;

					&.col-half {
						width: 50%;
					}

					>span {
						&:first-child {
							min-width: 100px;
							margin-right: 10px;
							float: left;
						}

						&:last-child {
							overflow: hidden;
							display: block;
						}
					}
				}
			}
		}

		&-form {
			&-foot {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				p {
					font-size: 18px;
					margin: 0;
				}

				.btn {
					margin: 0;
				}
			}
		}
	}

	&-foot {
		padding: 20px 0 10px
	}
}

.cs-radio {
	display: inline-block;
	position: relative;

	>label {
		padding-left: 25px;

		&::before {
			content: "";
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border: 1px solid #979797;
			position: absolute;
			left: 0;
			top: 0;
		}

		&::after {
			content: "";
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: #292929;
			position: absolute;
			left: 4px;
			top: 4px;
			transition: all 0.3s ease;
			transform: scale(0.5);
			opacity: 0;
		}
	}

	>input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;

		&:checked {
			+label {
				&::after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
}

.cs-accordion {
	margin-top: 5px;
	background: #EBF1F3;
	padding: 0 11px;
	font-size: 13px;

	&-title {
		font-size: 12px;
		font-weight: normal;
		text-transform: uppercase;
		padding: 8px 10px 8 0;
		position: relative;

		span {
			position: absolute;
			right: 0;
			top: 8px;
			transition: all 0.3s ease;
		}
	}

	&-content {
		display: none;
		padding-bottom: 10px;
	}

	&.open {
		.cs-accordion-title {
			span {
				transform: rotate(180deg);
			}
		}

		.cs-accordion-content {
			display: block;
		}
	}
}