.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1000;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

// .layout {
//   + .container__wrap {
//     .load__icon {
//       position: fixed;
//       left: calc((100vw - 250px)/2 + 250px - 16px);
//       top: calc(50vh - 16px); 
//       z-index: 1;
//     }
//   }
//   &.layout--collapse {
//     + .container__wrap {
//       .load__icon {
//         left: calc((100vw - 60px)/2 + 60px - 16px);
//       }
//     }
//   }
// }

main {
  .load__icon {
      z-index: 1;
      position: absolute;
      top: calc(50% + 60px - 16px);
      left: calc(50vw - 16px);
    }
  &:not(.sidebar-hidden) {
    .load__icon {
      left: calc((100vw - 60px - 30px)/2  + 60px - 16px);
    }
    &.sidebar-open {
      .load__icon {
        position: fixed;
        left: calc((100vw - 240px - 30px)/2  + 240px - 16px); 
      }
    }
  }

  
  

  //   &.layout--collapse {
  //   + .container__wrap {
  //     .load__icon {
  //       left: calc((100vw - 60px)/2 + 60px - 16px);
  //     }
  //   }
  // }
}



.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
